import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../assets/site/img/ORCID-iD_icon_unauth_vector.svg'
import _imports_1 from '@/assets/site/img/re3-data-logo-mono.jpg'
import _imports_2 from '@/assets/site/img/geosphere-austria-logo.jpg'
import _imports_3 from '@/assets/site/img/base_logo.png'


const _hoisted_1 = {
  id: "page_style",
  class: "flex flex-col items-center w-full h-full bg-transparent overflow-hidden text-sm",
  autocomplete: "off"
}
const _hoisted_2 = { class: "search-banner w-full bg-cover bg-no-repeat py-24 min-h-[400px] bg-[position:50%_25%]" }
const _hoisted_3 = {
  key: 0,
  class: "py-5"
}
const _hoisted_4 = { class: "w-11/12 sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto" }
const _hoisted_5 = { class: "flex flex-wrap" }
const _hoisted_6 = { class: "w-full lg:w-8/12 md:px-5 py-3" }
const _hoisted_7 = { class: "flex justify-between items-center pt-1 pb-2" }
const _hoisted_8 = { class: "px-2 py-1 text-xs font-normal text-gray-700 italic bg-white rounded-md border border-gray-300 shadow-sm" }
const _hoisted_9 = { class: "flex space-x-3" }
const _hoisted_10 = ["title"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["title"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = ["title"]
const _hoisted_15 = { class: "bg-white border shadow rounded p-4" }
const _hoisted_16 = {
  key: 0,
  class: "flex flex-wrap py-3"
}
const _hoisted_17 = { class: "w-full font-bold text-black text-lg" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { class: "text-gray-700 pb-4" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "flex flex-wrap gap-1 items-center" }
const _hoisted_25 = ["href"]
const _hoisted_26 = { class: "absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 hidden group-hover:block bg-black text-white text-xs rounded px-2 py-1 whitespace-nowrap" }
const _hoisted_27 = { key: 1 }
const _hoisted_28 = { key: 1 }
const _hoisted_29 = ["href"]
const _hoisted_30 = {
  key: 2,
  class: "flex flex-wrap pb-10"
}
const _hoisted_31 = { class: "w-full" }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { key: 1 }
const _hoisted_36 = { class: "flex flex-wrap mb-1" }
const _hoisted_37 = {
  key: 0,
  class: "w-full"
}
const _hoisted_38 = { class: "relative" }
const _hoisted_39 = { class: "hidden md:table w-full border border-gray-300 mb-4 text-xs" }
const _hoisted_40 = { class: "bg-gray-100" }
const _hoisted_41 = { class: "border px-4 py-2 text-left" }
const _hoisted_42 = { class: "border px-4 py-2 text-left" }
const _hoisted_43 = { class: "border px-4 py-2 text-left" }
const _hoisted_44 = { class: "border px-4 py-2 text-left" }
const _hoisted_45 = { class: "border px-4 py-2" }
const _hoisted_46 = { class: "border px-4 py-2" }
const _hoisted_47 = { class: "border px-4 py-2" }
const _hoisted_48 = { class: "border px-4 py-2 items-center justify-center" }
const _hoisted_49 = ["title", "href"]
const _hoisted_50 = {
  key: 1,
  class: "pr-1"
}
const _hoisted_51 = ["title", "onClick"]
const _hoisted_52 = { class: "font-sans font-bold uppercase" }
const _hoisted_53 = { class: "truncate mb-1" }
const _hoisted_54 = ["href"]
const _hoisted_55 = { class: "mb-1" }
const _hoisted_56 = {
  key: 0,
  class: "absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center text-white text-center text-xs md:text-lg p-4 rounded-lg"
}
const _hoisted_57 = { class: "font-semibold" }
const _hoisted_58 = { class: "font-bold" }
const _hoisted_59 = { class: "hidden md:inline" }
const _hoisted_60 = { class: "font-semibold" }
const _hoisted_61 = { class: "italic font-normal" }
const _hoisted_62 = { key: 0 }
const _hoisted_63 = ["src"]
const _hoisted_64 = { key: 1 }
const _hoisted_65 = { class: "overflow-auto max-h-[500px] sm:max-w-3xl lg:max-w-5xl border border-gray-300" }
const _hoisted_66 = { class: "table-auto w-full text-left border-collapse" }
const _hoisted_67 = { class: "bg-gray-100 sticky top-0 z-10" }
const _hoisted_68 = {
  key: 0,
  class: "text-gray-600 py-2"
}
const _hoisted_69 = {
  key: 0,
  class: "text-center text-gray-500 py-4"
}
const _hoisted_70 = { key: 2 }
const _hoisted_71 = ["src"]
const _hoisted_72 = { key: 3 }
const _hoisted_73 = { class: "text-gray-500" }
const _hoisted_74 = {
  key: 0,
  class: "font-bold text-black uppercase"
}
const _hoisted_75 = { key: 0 }
const _hoisted_76 = {
  key: 0,
  class: "flex flex-wrap mb-8 mt-3"
}
const _hoisted_77 = { class: "w-full font-bold uppercase mb-4" }
const _hoisted_78 = {
  key: 0,
  class: "w-full justify-normal"
}
const _hoisted_79 = { key: 0 }
const _hoisted_80 = { key: 1 }
const _hoisted_81 = { key: 0 }
const _hoisted_82 = { key: 1 }
const _hoisted_83 = { key: 1 }
const _hoisted_84 = {
  key: 1,
  class: "flex flex-wrap mb-8"
}
const _hoisted_85 = { class: "w-full font-bold uppercase mb-4" }
const _hoisted_86 = {
  key: 0,
  class: "w-full"
}
const _hoisted_87 = { key: 0 }
const _hoisted_88 = { key: 1 }
const _hoisted_89 = { key: 0 }
const _hoisted_90 = { key: 1 }
const _hoisted_91 = { key: 1 }
const _hoisted_92 = { class: "flex flex-wrap mb-8" }
const _hoisted_93 = { class: "w-full font-bold uppercase mb-2" }
const _hoisted_94 = { key: 0 }
const _hoisted_95 = { key: 1 }
const _hoisted_96 = { class: "flex flex-wrap mb-8" }
const _hoisted_97 = { class: "w-full font-bold uppercase mb-2" }
const _hoisted_98 = { key: 0 }
const _hoisted_99 = { key: 1 }
const _hoisted_100 = { class: "flex flex-wrap mb-8" }
const _hoisted_101 = { class: "w-full font-bold uppercase mb-2" }
const _hoisted_102 = { key: 0 }
const _hoisted_103 = { key: 1 }
const _hoisted_104 = { class: "bg-white border shadow rounded p-4 mb-4 relative" }
const _hoisted_105 = { class: "flex justify-between items-center pb-2" }
const _hoisted_106 = { class: "font-bold text-black uppercase py-1" }
const _hoisted_107 = ["title"]
const _hoisted_108 = { class: "text-gray-700" }
const _hoisted_109 = { class: "pb-2" }
const _hoisted_110 = ["href"]
const _hoisted_111 = { class: "w-full lg:w-4/12 py-3" }
const _hoisted_112 = { class: "locale-changer flex justify-end items-center pt-1 pb-2" }
const _hoisted_113 = ["title"]
const _hoisted_114 = ["onClick"]
const _hoisted_115 = { key: 0 }
const _hoisted_116 = { class: "bg-white border shadow rounded p-4 mb-5" }
const _hoisted_117 = { class: "text-center text-gray-700 text-xs uppercase font-bold py-2" }
const _hoisted_118 = { key: 0 }
const _hoisted_119 = { key: 0 }
const _hoisted_120 = { key: 0 }
const _hoisted_121 = { key: 1 }
const _hoisted_122 = { key: 0 }
const _hoisted_123 = { key: 1 }
const _hoisted_124 = { key: 1 }
const _hoisted_125 = { class: "bg-white border shadow rounded p-4 mb-5" }
const _hoisted_126 = { class: "uppercase font-bold text-black pb-5" }
const _hoisted_127 = { class: "text-gray-700 pb-5" }
const _hoisted_128 = { class: "uppercase font-semibold pb-3" }
const _hoisted_129 = { key: 0 }
const _hoisted_130 = {
  key: 0,
  class: "flex flex-wrap gap-1"
}
const _hoisted_131 = ["title"]
const _hoisted_132 = {
  key: 1,
  class: "flex flex-wrap gap-1"
}
const _hoisted_133 = ["title"]
const _hoisted_134 = ["href"]
const _hoisted_135 = { key: 1 }
const _hoisted_136 = { class: "text-gray-700 pb-5" }
const _hoisted_137 = { class: "uppercase font-semibold pb-1" }
const _hoisted_138 = { key: 0 }
const _hoisted_139 = { class: "font-normal" }
const _hoisted_140 = {
  key: 0,
  target: "_blank",
  class: "text-gray-700",
  href: 'https://creativecommons.org/licenses/by/4.0/'
}
const _hoisted_141 = { class: "text-secondary-tethys hover:underline pl-1" }
const _hoisted_142 = {
  key: 1,
  target: "_blank",
  class: "text-gray-700",
  href: 'https://creativecommons.org/licenses/by-sa/4.0/'
}
const _hoisted_143 = { class: "text-secondary-tethys hover:underline pl-1" }
const _hoisted_144 = { class: "text-gray-700 pb-5" }
const _hoisted_145 = { class: "uppercase font-semibold pb-3" }
const _hoisted_146 = { key: 0 }
const _hoisted_147 = {
  key: 0,
  class: "flex flex-wrap gap-1"
}
const _hoisted_148 = {
  key: 1,
  class: "flex flex-wrap gap-1"
}
const _hoisted_149 = ["href", "title"]
const _hoisted_150 = { key: 1 }
const _hoisted_151 = { class: "text-gray-700 pb-5" }
const _hoisted_152 = { class: "uppercase font-semibold pb-1" }
const _hoisted_153 = { key: 0 }
const _hoisted_154 = ["title"]
const _hoisted_155 = { class: "relative w-full max-w-xl p-5 bg-white rounded-lg shadow" }
const _hoisted_156 = { class: "flex items-start justify-between p-4 mb-4 border-b rounded-t" }
const _hoisted_157 = { class: "text-sm font-bold text-gray-700 bg-white rounded-md border border-gray-300 shadow-sm px-2 py-1" }
const _hoisted_158 = { class: "font-semibold italic text-left pl-3 py-1" }
const _hoisted_159 = { class: "p-4 text-justify" }
const _hoisted_160 = { key: 1 }
const _hoisted_161 = { class: "text-gray-700 pb-4" }
const _hoisted_162 = { class: "uppercase font-semibold pb-1" }
const _hoisted_163 = { class: "bg-white border shadow rounded p-4 mb-5" }
const _hoisted_164 = { class: "text-gray-700" }
const _hoisted_165 = { class: "uppercase text-black font-bold pb-3" }
const _hoisted_166 = { key: 0 }
const _hoisted_167 = { class: "pl-1" }
const _hoisted_168 = { key: 0 }
const _hoisted_169 = ["href"]
const _hoisted_170 = {
  key: 1,
  class: "text-gray-700"
}
const _hoisted_171 = { key: 0 }
const _hoisted_172 = ["href"]
const _hoisted_173 = {
  key: 1,
  class: "block text-sm italic pb-2 pl-4"
}
const _hoisted_174 = { key: 1 }
const _hoisted_175 = { class: "uppercase font-semibold" }
const _hoisted_176 = { class: "font-semibold" }
const _hoisted_177 = { class: "font-semibold" }
const _hoisted_178 = { key: 0 }
const _hoisted_179 = { class: "font-semibold" }
const _hoisted_180 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vs_input = _resolveComponent("vs-input")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Minimap = _resolveComponent("Minimap")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_vs_input, {
        propDisplay: _ctx.searchTerm,
        placeholder: 'Enter your search term...',
        onSearchChange: _ctx.onSearch
      }, null, 8, ["propDisplay", "onSearchChange"])
    ]),
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('published') + " " + _ctx.getSimpleDate(_ctx.dataset.server_date_published)), 1),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("span", {
                      class: "inline-block px-2 py-1 text-xs font-normal text-white bg-gray-500 rounded shadow-sm",
                      title: _ctx.$t('data_type')
                    }, [
                      _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fas fa-file" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.dataset.type), 1)
                    ], 8, _hoisted_10),
                    (_ctx.dataset.hasLicenses())
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset.licenses, (license) => {
                            return (_openBlock(), _createElementBlock("span", {
                              key: license.id
                            }, [
                              (_ctx.openAccessLicences.includes(license.name))
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "inline-block px-2 py-1 text-xs font-normal text-white bg-primary-tethys rounded shadow-sm",
                                    title: _ctx.$t('license_open')
                                  }, _cache[5] || (_cache[5] = [
                                    _createElementVNode("i", { class: "fas fa-lock-open" }, null, -1)
                                  ]), 8, _hoisted_12))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.dataset.hasEmbargoPassed())
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                          _createElementVNode("span", {
                            class: "inline-block px-2 py-1 text-xs font-normal text-white bg-red-800 rounded shadow-sm",
                            title: _ctx.$t('dataset_under_embargo_icon')
                          }, _cache[6] || (_cache[6] = [
                            _createElementVNode("i", { class: "fa-solid fa-hourglass-half" }, null, -1)
                          ]), 8, _hoisted_14)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  (_ctx.dataset.hasOwnProperty('titles'))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                        _createElementVNode("span", _hoisted_17, [
                          (_ctx.dataset.MainTitle?.language.toUpperCase() == _ctx.$i18n.locale)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_18, [
                                _createElementVNode("p", null, _toDisplayString(_ctx.dataset.MainTitle?.value), 1)
                              ]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_19, [
                                (_ctx.dataset.hasTranslatedTitle())
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_20, _toDisplayString(_ctx.dataset.TranslatedTitle?.value), 1))
                                  : (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(_ctx.dataset.MainTitle?.value), 1))
                              ]))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_22, [
                    (_ctx.dataset.authors.length > 0)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_23, [
                          _createElementVNode("span", _hoisted_24, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authorsList, (author, index) => {
                              return (_openBlock(), _createElementBlock("span", {
                                key: index,
                                class: "flex items-center"
                              }, [
                                _createElementVNode("span", null, _toDisplayString(author.displayName), 1),
                                (author.identifier_orcid != null)
                                  ? (_openBlock(), _createElementBlock("a", {
                                      key: 0,
                                      href: 'https://orcid.org/' + author.identifier_orcid,
                                      target: "_blank",
                                      class: "relative group px-1"
                                    }, [
                                      _cache[7] || (_cache[7] = _createElementVNode("img", {
                                        src: _imports_0,
                                        alt: "ORCID Logo",
                                        class: "w-4 h-4"
                                      }, null, -1)),
                                      _createElementVNode("span", _hoisted_26, _toDisplayString(author.displayName + " - " + _ctx.$t('visit_orcid')), 1)
                                    ], 8, _hoisted_25))
                                  : _createCommentVNode("", true),
                                (index < _ctx.authorsList.length - 1)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString("; ")))
                                  : _createCommentVNode("", true)
                              ]))
                            }), 128))
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_28, "-"))
                  ]),
                  (_ctx.dataset.identifier)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        target: "_blank",
                        href: 'https://doi.org/' + _ctx.dataset.identifier.value,
                        class: "flex text-secondary-tethys hover:underline pb-5"
                      }, _toDisplayString('https://doi.org/' + _ctx.dataset.identifier.value), 9, _hoisted_29))
                    : _createCommentVNode("", true),
                  (_ctx.dataset.hasOwnProperty('abstracts'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                        _createElementVNode("div", _hoisted_31, [
                          (_ctx.dataset.MainAbstract?.language.toUpperCase() == _ctx.$i18n.locale)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_32, [
                                _createElementVNode("p", null, _toDisplayString(_ctx.dataset.MainAbstract?.value), 1)
                              ]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_33, [
                                (_ctx.dataset.hasTranslatedAbstract())
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_34, _toDisplayString(_ctx.dataset.TranslatedAbstract?.value), 1))
                                  : (_openBlock(), _createElementBlock("p", _hoisted_35, _toDisplayString(_ctx.dataset.MainAbstract?.value), 1))
                              ]))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_36, [
                    (_ctx.dataset.files.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                          _createElementVNode("div", _hoisted_38, [
                            _createElementVNode("table", _hoisted_39, [
                              _createElementVNode("thead", null, [
                                _createElementVNode("tr", _hoisted_40, [
                                  _createElementVNode("th", _hoisted_41, _toDisplayString(_ctx.$t('file')), 1),
                                  _createElementVNode("th", _hoisted_42, _toDisplayString(_ctx.$t('format')), 1),
                                  _createElementVNode("th", _hoisted_43, _toDisplayString(_ctx.$t('size')), 1),
                                  _createElementVNode("th", _hoisted_44, _toDisplayString(_ctx.$t('action')), 1)
                                ])
                              ]),
                              _createElementVNode("tbody", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset.files, (file) => {
                                  return (_openBlock(), _createElementBlock("tr", {
                                    key: file.id,
                                    class: "hover:bg-gray-50"
                                  }, [
                                    _createElementVNode("td", _hoisted_45, _toDisplayString(file.label), 1),
                                    _createElementVNode("td", _hoisted_46, _toDisplayString(_ctx.getExtension(file.path_name)), 1),
                                    _createElementVNode("td", _hoisted_47, _toDisplayString(_ctx.formatSize(file.file_size)), 1),
                                    _createElementVNode("td", _hoisted_48, [
                                      (_ctx.dataset.hasEmbargoPassed())
                                        ? (_openBlock(), _createElementBlock("a", {
                                            key: 0,
                                            class: "inline-block px-2 py-1 border border-gray-300 shadow-sm hover:bg-gray-300 transition-colors text-primary-tethys rounded cursor-pointer",
                                            title: _ctx.$t('download_file'),
                                            target: "_blank",
                                            href: _ctx.portal + file.id
                                          }, _cache[8] || (_cache[8] = [
                                            _createElementVNode("i", { class: "fas fa-download text-xs" }, null, -1)
                                          ]), 8, _hoisted_49))
                                        : _createCommentVNode("", true),
                                      (_ctx.isFileSupported(_ctx.getExtension(file.path_name)))
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_50))
                                        : _createCommentVNode("", true),
                                      (_ctx.isFileSupported(_ctx.getExtension(file.path_name)))
                                        ? (_openBlock(), _createElementBlock("span", {
                                            key: 2,
                                            class: "inline-block px-2 py-1 border border-gray-300 shadow-sm hover:bg-gray-300 transition-colors text-primary-tethys rounded cursor-pointer",
                                            title: _ctx.$t('preview_file'),
                                            onClick: ($event: any) => (_ctx.previewFile(file.label, _ctx.getExtension(file.path_name), _ctx.portal + file.id))
                                          }, _cache[9] || (_cache[9] = [
                                            _createElementVNode("i", { class: "fa-solid fa-eye text-xs" }, null, -1)
                                          ]), 8, _hoisted_51))
                                        : _createCommentVNode("", true)
                                    ])
                                  ]))
                                }), 128))
                              ])
                            ]),
                            _createVNode(_component_Accordion, {
                              class: "block md:hidden pb-4",
                              customClass: "files-small-screen",
                              itemCount: 1
                            }, {
                              title: _withCtx(({ index }) => [
                                _createElementVNode("p", _hoisted_52, _toDisplayString(_ctx.$t('files')), 1)
                              ]),
                              content: _withCtx(({ index }) => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset.files, (file) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: file.id,
                                    class: "border-b border-b-gray-300 mb-1 p-4"
                                  }, [
                                    _createElementVNode("div", _hoisted_53, [
                                      _createElementVNode("a", {
                                        target: "_blank",
                                        href: _ctx.portal + file.id,
                                        class: "text-primary-tethys font-bold hover:underline"
                                      }, [
                                        _cache[10] || (_cache[10] = _createElementVNode("i", { class: "fas fa-file-download mr-1" }, null, -1)),
                                        _createTextVNode(" " + _toDisplayString(file.label), 1)
                                      ], 8, _hoisted_54)
                                    ]),
                                    _createElementVNode("div", _hoisted_55, [
                                      _createElementVNode("strong", null, _toDisplayString(_ctx.$t('format') + ': '), 1),
                                      _createTextVNode(" " + _toDisplayString(_ctx.getExtension(file.path_name)), 1)
                                    ]),
                                    _createElementVNode("div", null, [
                                      _createElementVNode("strong", null, _toDisplayString(_ctx.$t('size') + ': '), 1),
                                      _createTextVNode(" " + _toDisplayString(_ctx.formatSize(file.file_size)), 1)
                                    ])
                                  ]))
                                }), 128))
                              ]),
                              _: 1
                            }),
                            (!_ctx.dataset.hasEmbargoPassed())
                              ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                                  _createElementVNode("p", _hoisted_57, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('dataset_under_embargo') + ": ") + " ", 1),
                                    _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.getSimpleDate(_ctx.dataset.embargo_date)), 1),
                                    _cache[11] || (_cache[11] = _createTextVNode()),
                                    _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                                    _createElementVNode("span", _hoisted_59, _toDisplayString(_ctx.$t('meanwhile_access_restricted')), 1)
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_Accordion, {
                    class: "hidden md:block pb-4",
                    customClass: "coverage",
                    itemCount: 1,
                    ref: "previewAccordion"
                  }, {
                    title: _withCtx(({ index }) => [
                      _createElementVNode("div", _hoisted_60, [
                        _createTextVNode(_toDisplayString(_ctx.$t('preview_file') + ": "), 1),
                        _createElementVNode("span", _hoisted_61, _toDisplayString(_ctx.fileLabel + "." + _ctx.fileType), 1)
                      ])
                    ]),
                    content: _withCtx(({ index }) => [
                      (_ctx.fileType === 'pdf')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                            _createElementVNode("iframe", {
                              src: _ctx.fileUrl,
                              width: "100%",
                              height: "500px",
                              frameborder: "0",
                              class: "border border-gray-300"
                            }, null, 8, _hoisted_63)
                          ]))
                        : (_ctx.fileType === 'csv' || _ctx.fileType === 'txt')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                              _createElementVNode("div", _hoisted_65, [
                                _createElementVNode("table", _hoisted_66, [
                                  _createElementVNode("thead", _hoisted_67, [
                                    _createElementVNode("tr", null, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textHeaders, (header, idx) => {
                                        return (_openBlock(), _createElementBlock("th", {
                                          key: 'header-' + idx,
                                          scope: "col",
                                          class: "border-b border-gray-300 px-1 py-1 text-xs font-semibold text-gray-700 whitespace-normal"
                                        }, _toDisplayString(header), 1))
                                      }), 128))
                                    ])
                                  ]),
                                  _createElementVNode("tbody", null, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textRows, (row, idx) => {
                                      return (_openBlock(), _createElementBlock("tr", {
                                        key: 'row-' + idx,
                                        class: "hover:bg-gray-100"
                                      }, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (cell, i) => {
                                          return (_openBlock(), _createElementBlock("td", {
                                            key: 'cell-' + idx + '-' + i,
                                            class: "border-b border-gray-200 px-1 py-1 text-xs text-gray-600 whitespace-nowrap"
                                          }, _toDisplayString(cell), 1))
                                        }), 128))
                                      ]))
                                    }), 128))
                                  ]),
                                  (_ctx.fileType === 'txt')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_68, " ... only the first 100 lines are previewed ... "))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              (!_ctx.textHeaders.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_69, " No data available to display. "))
                                : _createCommentVNode("", true)
                            ]))
                          : (_ctx.fileType === 'jpg' || _ctx.fileType === 'png')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_70, [
                                _createElementVNode("img", {
                                  src: _ctx.fileUrl,
                                  alt: "Image Preview",
                                  class: "max-w-full border border-gray-300"
                                }, null, 8, _hoisted_71)
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_72, [
                                _createElementVNode("p", _hoisted_73, _toDisplayString(_ctx.$t('click_in_preview_icon')), 1)
                              ]))
                    ]),
                    _: 1
                  }, 512)
                ]),
                _createVNode(_component_Accordion, {
                  class: "bg-white text-gray-700 rounded mb-5",
                  itemCount: 1
                }, {
                  title: _withCtx(({ index }) => [
                    (index === 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_74, _toDisplayString(_ctx.$t('further_details')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  content: _withCtx(({ index }) => [
                    (index === 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_75, [
                          (_ctx.dataset.hasOwnProperty('abstracts'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_76, [
                                _createElementVNode("span", _hoisted_77, _toDisplayString(_ctx.$t('method') + ':'), 1),
                                (_ctx.dataset.hasMethodsAbstract())
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_78, [
                                      (_ctx.dataset.MethodsAbstract?.language.toUpperCase() == _ctx.$i18n.locale)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_79, [
                                            _createElementVNode("p", null, _toDisplayString(_ctx.dataset.MethodsAbstract?.value), 1)
                                          ]))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_80, [
                                            (_ctx.dataset.hasTranslatedMethodsAbstract())
                                              ? (_openBlock(), _createElementBlock("p", _hoisted_81, _toDisplayString(_ctx.dataset.TranslatedMethodsAbstract?.value), 1))
                                              : (_openBlock(), _createElementBlock("p", _hoisted_82, _toDisplayString(_ctx.dataset.MethodsAbstract?.value), 1))
                                          ]))
                                    ]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_83, "-"))
                              ]))
                            : _createCommentVNode("", true),
                          (_ctx.dataset.hasOwnProperty('abstracts'))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_84, [
                                _createElementVNode("span", _hoisted_85, _toDisplayString(_ctx.$t('series_information') + ':'), 1),
                                (_ctx.dataset.hasSeriesInformationAbstract())
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_86, [
                                      (_ctx.dataset.SeriesInformationAbstract?.language.toUpperCase() == _ctx.$i18n.locale)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_87, [
                                            _createElementVNode("p", null, _toDisplayString(_ctx.dataset.SeriesInformationAbstract?.value), 1)
                                          ]))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_88, [
                                            (_ctx.dataset.hasTranslatedSeriesInformationAbstract())
                                              ? (_openBlock(), _createElementBlock("p", _hoisted_89, _toDisplayString(_ctx.dataset.TranslatedSeriesInformationAbstract?.value), 1))
                                              : (_openBlock(), _createElementBlock("p", _hoisted_90, _toDisplayString(_ctx.dataset.SeriesInformationAbstract?.value), 1))
                                          ]))
                                    ]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_91, "-"))
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_92, [
                            _createElementVNode("span", _hoisted_93, _toDisplayString(_ctx.$t('publication_language') + ':'), 1),
                            (_ctx.getLanguage(_ctx.dataset.language) == 'Deutsch')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_94, _toDisplayString(_ctx.$t('german')), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_95, _toDisplayString(_ctx.$t('english')), 1))
                          ]),
                          _createElementVNode("div", _hoisted_96, [
                            _createElementVNode("span", _hoisted_97, _toDisplayString(_ctx.$t('embargo') + ':'), 1),
                            (_ctx.dataset.embargo_date)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_98, _toDisplayString(_ctx.getHumanDate(_ctx.dataset.embargo_date)), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_99, "-"))
                          ]),
                          _createElementVNode("div", _hoisted_100, [
                            _createElementVNode("span", _hoisted_101, _toDisplayString(_ctx.$t('contributor') + ':'), 1),
                            (_ctx.dataset.hasContributors())
                              ? (_openBlock(), _createElementBlock("span", _hoisted_102, _toDisplayString(_ctx.dataset.contributors.map((u) => u.full_name).join(", ")), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_103, "-"))
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_104, [
                  _createElementVNode("div", _hoisted_105, [
                    _createElementVNode("p", _hoisted_106, _toDisplayString(_ctx.$t('citation') + ':'), 1),
                    _createElementVNode("span", {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClipboard(_ctx.dataset.identifier.value.toString()))),
                      class: "cursor-pointer px-2 py-1 text-xs font-normal text-gray-700 italic bg-gray-100 rounded-md border border-gray-300 shadow-sm hover:bg-gray-200",
                      title: _ctx.$t('copy_citation')
                    }, _cache[13] || (_cache[13] = [
                      _createElementVNode("i", { class: "fas fa-copy" }, null, -1)
                    ]), 8, _hoisted_107)
                  ]),
                  _createElementVNode("div", _hoisted_108, [
                    _createElementVNode("p", _hoisted_109, [
                      _createTextVNode(_toDisplayString(_ctx.getCitation()) + " ", 1),
                      (_ctx.dataset.identifier)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            target: "_blank",
                            class: "text-secondary-tethys hover:underline",
                            href: 'https://doi.org/' + _ctx.dataset.identifier.value
                          }, " (" + _toDisplayString("https://doi.org/" + _ctx.dataset.identifier.value) + ") ", 9, _hoisted_110))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_111, [
                _createElementVNode("div", _hoisted_112, [
                  _createElementVNode("span", {
                    class: "px-2 py-1 text-xs font-normal text-gray-700 bg-white rounded-md border border-gray-300 shadow-sm",
                    title: _ctx.$t('select_details_language')
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales, (locale, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: `locale-${locale}`
                      }, [
                        _createElementVNode("span", {
                          class: _normalizeClass(["cursor-pointer", { 'font-bold text-secondary-tethys': locale === _ctx.$i18n.locale }]),
                          onClick: ($event: any) => (_ctx.changeLocale(locale))
                        }, _toDisplayString(locale.toUpperCase()), 11, _hoisted_114),
                        (index < _ctx.$i18n.availableLocales.length - 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_115, " / "))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ], 8, _hoisted_113)
                ]),
                _createElementVNode("div", _hoisted_116, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_Minimap, {
                      bounds: _ctx.dataset.Bounds,
                      class: "relative z-10"
                    }, null, 8, ["bounds"])
                  ]),
                  _createElementVNode("h3", _hoisted_117, _toDisplayString(_ctx.$t('coverage')), 1),
                  _createVNode(_component_Accordion, {
                    customClass: "coverage",
                    itemCount: _ctx.coverageAttributes.length
                  }, {
                    title: _withCtx(({ index }) => [
                      _createTextVNode(_toDisplayString(_ctx.$t(_ctx.coverageAttributes[index].key)), 1)
                    ]),
                    content: _withCtx(({ index }) => [
                      (_ctx.coverageAttributes[index].values)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_118, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coverageAttributes[index].values, (value, label) => {
                              return (_openBlock(), _createElementBlock(_Fragment, { key: label }, [
                                value
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_119, [
                                      (_ctx.coverageAttributes[index].key == 'geolocation')
                                        ? (_openBlock(), _createElementBlock("p", _hoisted_120, _toDisplayString(_ctx.$t(label) + ": " + value.toFixed(6)), 1))
                                        : (_openBlock(), _createElementBlock("p", _hoisted_121, [
                                            (_ctx.coverageAttributes[index].key == 'elevation' || _ctx.coverageAttributes[index].key == 'depth')
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_122, _toDisplayString(_ctx.$t(label) + ": " + Intl.NumberFormat('de-de').format(value) + " m."), 1))
                                              : (_openBlock(), _createElementBlock("span", _hoisted_123, _toDisplayString(_ctx.$t(label) + ": " + value), 1))
                                          ]))
                                    ]))
                                  : _createCommentVNode("", true)
                              ], 64))
                            }), 128))
                          ]))
                        : (_openBlock(), _createElementBlock("p", _hoisted_124, "No data available"))
                    ]),
                    _: 1
                  }, 8, ["itemCount"])
                ]),
                _createElementVNode("div", _hoisted_125, [
                  _createElementVNode("div", _hoisted_126, _toDisplayString(_ctx.$t('details')), 1),
                  _createElementVNode("div", _hoisted_127, [
                    _createElementVNode("p", _hoisted_128, _toDisplayString(_ctx.$t('creator') + ':'), 1),
                    (_ctx.dataset.authors.length > 0)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_129, [
                          (_ctx.accessNotFromDoi())
                            ? (_openBlock(), _createElementBlock("span", _hoisted_130, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authorsList, (author, index) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    key: index,
                                    class: "pb-2 flex-shrink-0",
                                    title: _ctx.$t('search_creator')
                                  }, [
                                    _createVNode(_component_router_link, {
                                      to: { name: 'Search', params: { display: author.lastName, type: 'authors' } },
                                      class: "px-2 py-1 text-xs text-primary-tethys rounded-md border border-gray-300 shadow-sm hover:bg-gray-200 transition-colors"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(author.displayName), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["to"])
                                  ], 8, _hoisted_131))
                                }), 128))
                              ]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_132, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authorsList, (author, index) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    key: index,
                                    class: "pb-2 flex-shrink-0",
                                    title: _ctx.$t('search_creator')
                                  }, [
                                    _createElementVNode("a", {
                                      target: "_blank",
                                      href: _ctx.search_url + '/' + author.lastName + '/authors',
                                      class: "px-2 py-1 text-xs text-primary-tethys rounded-md border border-gray-300 shadow-sm hover:bg-gray-200 transition-colors"
                                    }, _toDisplayString(author.displayName), 9, _hoisted_134)
                                  ], 8, _hoisted_133))
                                }), 128))
                              ]))
                        ]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_135, "-"))
                  ]),
                  _createElementVNode("div", _hoisted_136, [
                    _createElementVNode("p", _hoisted_137, _toDisplayString(_ctx.$t('license') + ':'), 1),
                    (_ctx.dataset.hasLicenses())
                      ? (_openBlock(), _createElementBlock("p", _hoisted_138, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset.licenses, (license) => {
                            return (_openBlock(), _createElementBlock("label", {
                              key: license.id
                            }, [
                              _createElementVNode("span", _hoisted_139, [
                                (license.name=='CC-BY-4.0')
                                  ? (_openBlock(), _createElementBlock("a", _hoisted_140, [
                                      _cache[14] || (_cache[14] = _createElementVNode("i", { class: "fa-brands fa-creative-commons" }, null, -1)),
                                      _createElementVNode("span", _hoisted_141, _toDisplayString(license.name), 1)
                                    ]))
                                  : (_openBlock(), _createElementBlock("a", _hoisted_142, [
                                      _cache[15] || (_cache[15] = _createElementVNode("i", { class: "fa-brands fa-creative-commons" }, null, -1)),
                                      _createElementVNode("span", _hoisted_143, _toDisplayString(license.name), 1)
                                    ]))
                              ])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_144, [
                    _createElementVNode("p", _hoisted_145, _toDisplayString(_ctx.$t('keywords') + ':'), 1),
                    (_ctx.dataset.hasOwnProperty('subjects'))
                      ? (_openBlock(), _createElementBlock("p", _hoisted_146, [
                          (_ctx.accessNotFromDoi())
                            ? (_openBlock(), _createElementBlock("span", _hoisted_147, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset.subjects, (subject, index) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    key: subject.value,
                                    class: "pb-2 flex-shrink-0"
                                  }, [
                                    _createVNode(_component_router_link, {
                                      to: { name: 'Search', params: { display: subject.value, type: 'subjects' } },
                                      class: "px-2 py-1 text-xs text-gray-700 rounded-md border border-gray-300 shadow-sm hover:bg-gray-200 transition-colors",
                                      title: _ctx.$t('search_keyword')
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(subject.value), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["to", "title"])
                                  ]))
                                }), 128))
                              ]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_148, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset.subjects, (subject, index) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    key: subject.value,
                                    class: "pb-2 flex-shrink-0"
                                  }, [
                                    _createElementVNode("a", {
                                      target: "_blank",
                                      href: _ctx.search_url + '/' + subject.value + '/subjects',
                                      class: "px-2 py-1 text-xs text-gray-700 rounded-md border border-gray-300 shadow-sm hover:bg-gray-200 transition-colors",
                                      title: _ctx.$t('search_keyword')
                                    }, _toDisplayString(subject.value), 9, _hoisted_149)
                                  ]))
                                }), 128))
                              ]))
                        ]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_150, "-"))
                  ]),
                  _createElementVNode("div", _hoisted_151, [
                    _createElementVNode("p", _hoisted_152, _toDisplayString(_ctx.$t('project') + ':'), 1),
                    (_ctx.dataset.project != null)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_153, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.dataset.project.label), 1),
                          _createElementVNode("i", {
                            class: "fas fa-circle-info text-primary-tethys hover:text-black pl-1",
                            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showModal && _ctx.showModal(...args))),
                            title: _ctx.$t('project_details')
                          }, null, 8, _hoisted_154),
                          (_ctx.isShowModal)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)), ["self"])),
                                class: "fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50",
                                "aria-hidden": "true"
                              }, [
                                _createElementVNode("div", _hoisted_155, [
                                  _createElementVNode("div", _hoisted_156, [
                                    _createElementVNode("span", _hoisted_157, _toDisplayString(_ctx.dataset.project?.label), 1),
                                    _createElementVNode("p", _hoisted_158, _toDisplayString(_ctx.dataset.project?.name), 1),
                                    _createElementVNode("button", {
                                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
                                      type: "button",
                                      class: "text-gray-400 hover:text-gray-900 py-1"
                                    }, _cache[16] || (_cache[16] = [
                                      _createElementVNode("svg", {
                                        class: "w-5 h-5",
                                        fill: "currentColor",
                                        viewBox: "0 0 20 20",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }, [
                                        _createElementVNode("path", {
                                          "fill-rule": "evenodd",
                                          d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                                          "clip-rule": "evenodd"
                                        })
                                      ], -1)
                                    ]))
                                  ]),
                                  _createElementVNode("div", _hoisted_159, [
                                    _createElementVNode("p", null, _toDisplayString(_ctx.dataset.project.description), 1)
                                  ])
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_160, "-"))
                  ]),
                  _createElementVNode("div", _hoisted_161, [
                    _createElementVNode("p", _hoisted_162, _toDisplayString(_ctx.$t('publisher')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.dataset.publisher_name), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_163, [
                  _createElementVNode("div", _hoisted_164, [
                    _createElementVNode("p", _hoisted_165, _toDisplayString(_ctx.$t('references')), 1),
                    (_ctx.dataset.references.length> 0)
                      ? (_openBlock(), _createElementBlock("ul", _hoisted_166, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset.references, (reference, i) => {
                            return (_openBlock(), _createElementBlock("li", {
                              key: reference.id,
                              class: "pb-2"
                            }, [
                              _createElementVNode("p", null, [
                                _cache[17] || (_cache[17] = _createElementVNode("i", { class: "fa-solid fa-right-to-bracket text-primary-tethys pb-1" }, null, -1)),
                                _createElementVNode("span", _hoisted_167, _toDisplayString(_ctx.$t(reference.relation) + ":"), 1)
                              ]),
                              (reference.type == 'DOI' || reference.type == 'URL')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_168, [
                                    _createElementVNode("a", {
                                      target: "_blank",
                                      class: "block text-sm italic text-secondary-tethys hover:underline pb-2 pl-4",
                                      href: reference.value
                                    }, _toDisplayString(reference.label), 9, _hoisted_169)
                                  ]))
                                : (_openBlock(), _createElementBlock("span", _hoisted_170, [
                                    (reference.value.indexOf('85316') != -1 || reference.value.indexOf('900312') != -1)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_171, [
                                          _createElementVNode("a", {
                                            target: "_blank",
                                            class: "block text-sm italic text-secondary-tethys hover:underline pb-2 pl-4",
                                            href: 'https://bibliothek.geosphere.at/cgi-bin/koha/opac-search.pl?idx=&q=' + reference.value
                                          }, _toDisplayString(`(${reference.type}): ${reference.value}`), 9, _hoisted_172)
                                        ]))
                                      : (_openBlock(), _createElementBlock("span", _hoisted_173, _toDisplayString(`(${reference.type}): ${reference.value}`), 1))
                                  ]))
                            ]))
                          }), 128))
                        ]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_174, "-"))
                  ])
                ]),
                _createVNode(_component_Accordion, {
                  class: "pb-4",
                  itemCount: 1
                }, {
                  title: _withCtx(({ index }) => [
                    _createElementVNode("div", _hoisted_175, _toDisplayString(_ctx.$t('technical_metadata')), 1)
                  ]),
                  content: _withCtx(({ index }) => [
                    _createElementVNode("div", null, [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_176, _toDisplayString(_ctx.$t('persistent_identifier') + ': '), 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.dataset.url), 1)
                      ]),
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_177, _toDisplayString(_ctx.$t('status') + ': '), 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.dataset.server_state), 1)
                      ]),
                      (_ctx.dataset.hasOwnProperty('user'))
                        ? (_openBlock(), _createElementBlock("p", _hoisted_178, [
                            _createElementVNode("span", _hoisted_179, _toDisplayString(_ctx.$t('posted_by') + ': '), 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.dataset.user.login), 1)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_180, _toDisplayString(_ctx.$t('editor') + ': '), 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.dataset.creating_corporation), 1)
                      ])
                    ])
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _cache[18] || (_cache[18] = _createStaticVNode("<div class=\"w-full mx-auto mt-5 px-4\" data-v-7d28de9c><div class=\"flex flex-wrap justify-between items-center\" data-v-7d28de9c><div class=\"flex justify-center w-full md:w-1/3 p-4\" data-v-7d28de9c><a target=\"_blank\" href=\"https://www.re3data.org/repository/r3d100013400\" data-v-7d28de9c><img src=\"" + _imports_1 + "\" alt=\"re3 data logo\" class=\"w-full max-w-[200px]\" data-v-7d28de9c></a></div><div class=\"flex justify-center w-full md:w-1/3 p-4\" data-v-7d28de9c><a target=\"_blank\" href=\"http://www.geosphere.at/\" data-v-7d28de9c><img src=\"" + _imports_2 + "\" alt=\"logo geosphere austria\" class=\"w-full max-w-[400px]\" data-v-7d28de9c></a></div><div class=\"flex justify-center w-full md:w-1/3 p-4\" data-v-7d28de9c><a target=\"_blank\" href=\"https://www.base-search.net/Search/Results?q=coll:fttethysrdr&amp;refid=dctablede\" data-v-7d28de9c><img src=\"" + _imports_3 + "\" alt=\"logo base\" class=\"w-full max-w-[250px]\" data-v-7d28de9c></a></div></div></div>", 1))
  ]))
}