import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container map main" }
const _hoisted_2 = { id: "div-map" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MapComponent = _resolveComponent("MapComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MapComponent, {
        mapId: 'map',
        mapOptions: _ctx.mapOptions
      }, null, 8, ["mapOptions"])
    ]),
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"columns is-tablet\"><div class=\"column is-half\"><h2>Map views</h2><p>Ready to beyond the starter map? Check out these map views that you can quickly access.</p><ul class=\"icon-list\"><li><a href=\"javascript:window.open(window.location.href.split(&#39;?&#39;)[0],&#39;_self&#39;)\">alle Datenpublikationen</a></li><li><a href=\"javascript:window.open(window.location.href.split(&#39;?&#39;)[0]+&#39;?layer=gk50&#39;,&#39;_self&#39;)\">GK50 Kartendaten</a></li><li><a href=\"javascript:window.open(window.location.href.split(&#39;?&#39;)[0]+&#39;?layer=geofast&#39;,&#39;_self&#39;)\">Geofast Datensätze</a></li></ul></div><div class=\"column is-half\"><h2>Catalog views</h2><p>Read more detailed documentation on using the Tethys Research Data Repository.</p><ul class=\"icon-list\"><li><a href=\"https://tethys.at\">GK50 Kartendaten</a></li><li><a href=\"https://tethys.at\">Geofast Datenpublikationen</a></li><li><a href=\"https://tethys.at\">Rohstoff Geologie</a></li></ul></div></div>", 1))
  ]))
}