import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex rounded-none shadow-none border-b-2 border-gray-200" }
const _hoisted_2 = { class: "hidden lg:block py-2 w-1/5 max-w-56" }
const _hoisted_3 = { class: "pr-2 py-4 lg:px-4 lg:py-4 lg:w-4/5" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 0,
  class: "text-gray-500 pointer-events-none text-sm"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "pt-2 line-clamp-3 sm:line-clamp-2 relative overflow-hidden" }
const _hoisted_13 = { class: "text-sm" }
const _hoisted_14 = { class: "sm:space-x-3 pt-4 pb-2" }
const _hoisted_15 = { class: "inline-block px-2 py-1 text-xs font-normal text-gray-700 italic bg-white rounded-md border border-gray-300 shadow-sm" }
const _hoisted_16 = { class: "block sm:inline-block mt-2 sm:mt-0" }
const _hoisted_17 = ["title"]
const _hoisted_18 = ["title"]
const _hoisted_19 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResultsMinimap = _resolveComponent("ResultsMinimap")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (document) => {
    return (_openBlock(), _createElementBlock("div", {
      key: document.id,
      class: "flex items-start space-x-2"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ResultsMinimap, {
            bounds: _ctx.getBounds(document),
            mapId: document.id,
            class: "relative z-10"
          }, null, 8, ["bounds", "mapId"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createElementVNode("h4", null, [
              _createVNode(_component_router_link, {
                class: "text-black font-bold hover:underline no-underline cursor-pointer",
                to: { name: 'dataset', params: { datasetId: document.id } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(document.title_output), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            (document.identifier && document.identifier.length > 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                  _createElementVNode("a", {
                    target: "_blank",
                    href: 'https://doi.org/' + document.identifier[0],
                    class: "text-teal-500 text-sm"
                  }, _toDisplayString("https://doi.org/" + document.identifier[0] + " ➤"), 9, _hoisted_5),
                  _cache[1] || (_cache[1] = _createTextVNode("   ")),
                  (document.author && document.author.length > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                        (document.author.length === 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.simplifyAuthor(document.author[0])), 1))
                          : _createCommentVNode("", true),
                        (document.author.length > 1 && document.author.length < 3)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(document.author, (author, index) => {
                                return (_openBlock(), _createElementBlock("span", { key: index }, [
                                  _createTextVNode(_toDisplayString(_ctx.simplifyAuthor(author)) + " ", 1),
                                  (index < document.author.length - 1)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, "; "))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true),
                        (document.author.length >= 3)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(document.author.slice(0, 2), (author, index) => {
                                return (_openBlock(), _createElementBlock("span", { key: index }, [
                                  _createTextVNode(_toDisplayString(_ctx.simplifyAuthor(author)) + " ", 1),
                                  (index < 1)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, "; "))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128)),
                              _cache[0] || (_cache[0] = _createTextVNode(" et al. "))
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("p", _hoisted_12, [
              _createElementVNode("span", _hoisted_13, [
                _createTextVNode(_toDisplayString(document.abstract[0]) + " ", 1),
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "absolute bottom-0 right-0 bg-white" }, "...", -1))
              ])
            ]),
            _createElementVNode("p", _hoisted_14, [
              _createElementVNode("span", _hoisted_15, _toDisplayString("Published " + _ctx.convert(document.server_date_published, _ctx.$i18n.locale)), 1),
              _createElementVNode("span", _hoisted_16, [
                _createElementVNode("span", {
                  class: "inline-block px-2 py-1 text-xs font-normal text-white bg-gray-500 rounded shadow-sm",
                  title: _ctx.$t('data_type')
                }, [
                  _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-file" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(document.doctype), 1)
                ], 8, _hoisted_17),
                (_ctx.openAccessLicences.includes(document.licence))
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: "inline-block px-2 py-1 text-xs font-normal text-white bg-primary-tethys rounded shadow-sm ml-2 sm:ml-0",
                      title: _ctx.$t('license_open')
                    }, _cache[4] || (_cache[4] = [
                      _createElementVNode("i", { class: "fas fa-lock-open" }, null, -1)
                    ]), 8, _hoisted_18))
                  : _createCommentVNode("", true),
                (document.id == 236)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: "inline-block px-2 py-1 text-xs font-normal text-white bg-red-800 rounded shadow-sm ml-2 sm:ml-0",
                      title: _ctx.$t('dataset_under_embargo_icon')
                    }, _cache[5] || (_cache[5] = [
                      _createElementVNode("i", { class: "fa-solid fa-hourglass-half" }, null, -1)
                    ]), 8, _hoisted_19))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ])
    ]))
  }), 128))
}