<template>
    <div id="page_style" class="site-content page__style page__description" autocomplete="off">

        <div class="column">
            <div class="col text-center py-3">
                <h1>Tethys Research Data Repository</h1>
                <p class="lead">Data Publisher for Geoscience Austria</p>
                <hr class="center-line" />
            </div>
        </div>

        <section id="help" data-sr class="header-image align-items-center h-100">
            <vs-input v-bind:placeholder="'Enter your search term...'" @search-change="onSearch"></vs-input>
        </section>

        <div class="container">
            <section class="section">
                <div class="column is-full text-center">
                    <h1>ÜBER TETHYS</h1>
                    <p class="lead">Was sind unsere Schwerpunkte</p>
                    <hr class="center-line" />
                </div>
                <div class="column is-full text-left">
                    <p>
                        TETHYS RDR ist ein publizierendes Forschungsdatenrepositorium der GeoSphere Austria, das darauf spezialisiert ist, geowissenschaftliche
                        Forschungsdaten zu sammeln, zu speichern und der Öffentlichkeit zugänglich zu machen.
                    </p>
                    <p>
                        Zum derzeitigen Stand wird TETHYS RDR in deutscher Sprache bereitgestellt. Die englische Version befindet sich in Vorbereitung und wird
                        sukzessive ebenfalls zur Verfügung gestellt. Die Forschungsdatenpublikationen und die dazugehörigen Metadaten können in Deutsch und in
                        Englisch veröffentlicht werden. Tethys RDR hat den Anspruch, publizierte Datensätze unverändert, langfristig und nachhaltig
                        bereitzustellen. Dadurch ist Tethys ein Forschungsdatenrepositorium, das ein permanentes Referenzieren ermöglicht und somit die darin
                        publizierten Datensätze zitierfähig macht.
                    </p>
                    <p>
                        Der Erhaltungsplan
                        <a href="docs/PreservationPlanTethys.pdf" target="_blank" class="text-secondary"> (Preservation Plan) </a> beschreibt den Ansatz von
                        Tethys zur Definition und Umsetzung von Erhaltungsmaßnahmen.
                    </p>
                    <p>
                        Der Name Tethys kommt ursprünglich aus der griechischen Mythologie und benennt eine Titanin und Meeresgöttin. Ende des 19. Jahrhunderts
                        entdeckte Eduard Suess (1831-1914), ein bedeutender österreichischer Geologe, den mesozoischen Ozean und benannte ihn nach dieser
                        Tethys.
                    </p>
                </div>
            </section>

            <div>
                <div class="column is-full text-center">
                    <h1>TETHYS SERVICES</h1>
                    <p class="lead">Eine Übersicht unserer Dienstleistungen</p>
                    <hr class="center-line" />
                </div>
                <div class="columns is-desktop work-items">
                    <div class="column col-sm overlay-boxes">
                        <div class="card overlay work-back">
                            <img src="@/assets/site/img/box-1-hover.jpg" alt="Datenarchivierung" />
                            <div class="card-body box-1-hover">
                                <p class="text-white">
                                    Tethys RDR publiziert und archiviert nach den FAIR Prinzipien* nachhaltig so wie sicher geowissenschaftliche Datensätze in
                                    offenen, frei lesbaren Formaten. * FAIR data
                                </p>
                                <p class="text-white">
                                    <a href="docs/PreservationPlanTethys.pdf" target="_blank" class="text-secondary2">Preservation Plan</a>
                                </p>
                            </div>
                        </div>
                        <div class="card green work-front">
                            <img src="@/assets/site/img/box-1.jpg" alt="Datenarchivierung" />
                            <div class="card-body box-1">
                                <div class="custom-heading">DATENARCHIVIERUNG</div>
                            </div>
                        </div>
                    </div>

                    <div class="column col-sm overlay-boxes">
                        <div class="card overlay work-back">
                            <img src="@/assets/site/img/box-2-hover.jpg" alt="DATENPUBLIKATION" />
                            <div class="card-body box-1-hover">
                                <p class="text-white">
                                    Die Datensätze werden mit standardisierten Metadatenschemen publiziert und sind somit nicht nur auffindbar, wiederverwendbar
                                    und auch maschinenlesbar, sondern können dadurch auch einfach zitiert werden.
                                </p>
                            </div>
                        </div>
                        <div class="card green work-front">
                            <img src="@/assets/site/img/box-2.jpg" alt="Datenpublikation" />
                            <div class="card-body box-2">
                                <div class="custom-heading">DATENPUBLIKATION</div>
                            </div>
                        </div>
                    </div>

                    <div class="column col-sm overlay-boxes">
                        <div class="card overlay work-back">
                            <img src="@/assets/site/img/box-3-hover.jpg" alt="BEGUTACHTUNG" />
                            <div class="card-body box-1-hover">
                                <p class="text-white">
                                    Alle im Tethys RDR publizierten Datensätze werden auf technische sowie inhaltliche Vollständigkeit geprüft und werden bei
                                    Bedarf auch einer fachlichen Begutachtung unterworfen. Ein klassischer Peer Review-Prozess ist in Vorbereitung.
                                </p>
                            </div>
                        </div>
                        <div class="card green work-front">
                            <img src="@/assets/site/img/box-3.jpg" alt="BEGUTACHTUNG" />
                            <div class="card-body box-3">
                                <div class="custom-heading">BEGUTACHTUNG</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="clearfix mt-3"></div>

        <!-- <div class="bg-cover bg-center bg-no-repeat" style="background-image: url(./../../assets/site/img/page-breaker.jpg); padding: 6.2em;"></div> -->

        <!-- <div class="container-fluid page-breaker my-5"> -->
        <div class="page-breaker bg-cover bg-center bg-no-repeat sm:p-24 my-5 text-white">
        <!-- <div class="page-breaker bg-cover bg-center bg-no-repeat my-5 text-white"> -->
            <div class="container">
                <div class="block flex-[0_1_auto] p-3 text-center my-5">
                <!-- <div class="block flex-[0_1_auto] sm:py-40 sm:px-20 text-center my-5"> -->
                    <h1 class="">HABEN SIE FRAGEN?</h1>

                    <p class="p-5">
                    <!-- <p class="sm:py-5"> -->
                        TETHYS RDR ist ein publizierendes Forschungsdatenrepositorium der GeoSphere Austria, das darauf spezialisiert ist, geowissenschaftliche
                        Forschungsdaten zu sammeln, zu speichern und der Öffentlichkeit zugänglich zu machen. Die Datenpublikationen können sowohl in deutscher,
                        als auch in englischer Sprache publiziert werden. Durch die Bereitstellung der Datenpublikation zusammen mit Metadaten nach
                        standardisierten Schemata werden die Publikationen auffindbar und zitierbar.
                    </p>

                    <div class="mx-auto my-5" style="width: 170px">
                        <a href="/contact" class="button is-primary custom-button">
                            <i class="fas fa-sign-in-alt"></i>
                            <strong>KONTAKT</strong>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="container-fluid">
            <div class="columns is-mobile partner-logos">
                <div class="column col-sm text-center">
                    <a target="_blank" href="https://www.re3data.org/repository/r3d100013400"
                        ><img src="@/assets/site/img/re3-data-logo-mono.jpg" alt="re3 data logo"/>
                    </a>
                </div>
                <div class="column col-sm text-center">
                    <a target="_blank" href="http://www.geosphere.at/">
                        <img src="@/assets/site/img/geosphere-austria-logo.jpg" alt="logo geosphere austria" />
                    </a>
                </div>
                <div class="column col-sm text-center">
                    <a target="_blank" href="https://www.base-search.net/Search/Results?q=coll:fttethysrdr&refid=dctablede">
                        <img src="@/assets/site/img/base-logo.gif" alt="logo base" />
                    </a>
                </div>
            </div>
        </div> -->
        <div class="container-fluid">
            <!-- <div class="columns is-mobile partner-logos"> -->
            <div class="columns">
                <div class="column col-sm">
                    <div class="card mx-auto" style="width: 18rem; box-shadow: none; border: 0rem">
                        <div class="card-body">
                            <!-- <h5 class="card-title">About TETHYS</h5> -->
                            <a target="_blank" href="https://www.re3data.org/repository/r3d100013400">
                                <img src="@/assets/site/img/re3-data-logo-mono.jpg" alt="re3 data logo" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column col-sm">
                    <div class="card mx-auto" style="width: 28rem; box-shadow: none; border: 0rem">
                        <div class="card-body">
                            <a target="_blank" href="http://www.geosphere.at/">
                                <img src="@/assets/site/img/geosphere-austria-logo.jpg" alt="logo geosphere austria" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column col-sm">
                    <div class="card mx-auto" style="width: 18rem; box-shadow: none; border: 0rem">
                        <div class="card-body">
                            <a target="_blank" href="https://www.base-search.net/Search/Results?q=coll:fttethysrdr&refid=dctablede">
                                <img src="@/assets/site/img/base_logo.png" alt="logo base" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import HomeViewComponent from "./home-view-component";
export default HomeViewComponent;
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./home-view-component.scss";

.page-breaker {
    background-image: url(./../../assets/site/img/page-breaker.jpg);
    // background-size: cover;
    // background-position: center center;
    // background-repeat: no-repeat;
    // padding: 6.2em;
}

</style>
