<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
    data: {
        type: Object,
        default: () => ({}),
    },
});

const emit = defineEmits(["menu-click"]);

const totalPages = computed(() => Math.ceil(props.data.total / props.data.perPage));
const maxButtons = 7; // Adjusted to 7 for our case

const displayedPages = computed(() => {
    const currentPage = props.data.currentPage;
    const pages: Array<number | string> = [];

    if (totalPages.value <= maxButtons) {
        // Show all pages if they fit within maxButtons
        for (let i = 1; i <= totalPages.value; i++) {
            pages.push(i);
        }
    } else {
        pages.push(1); // Always show the first page

        const middleRange = maxButtons - 2; // Remaining slots after first/last pages

        if (currentPage > 3) {
            pages.push("..."); // Add left ellipsis
        }

        // Define middle range: Center around current page, keeping within limits
        let startPage = Math.max(2, currentPage - 1);
        let endPage = Math.min(totalPages.value - 1, currentPage + 1);

        if (currentPage <= 3) {
            endPage = middleRange; // Ensure first few pages get shown
        }
        if (currentPage >= totalPages.value - 2) {
            startPage = totalPages.value - (middleRange - 1); // Ensure last few pages show correctly
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        if (currentPage < totalPages.value - 2) {
            pages.push("..."); // Add right ellipsis
        }

        pages.push(totalPages.value); // Always show last page
    }

    return pages;
});

const goToPage = (page: number | string) => {
    if (typeof page === "number") {
        emit("menu-click", page);
    }
};

const prevClick = () => {
    if (props.data.currentPage > 1) {
        emit("menu-click", props.data.currentPage - 1);
    }
};

const nextClick = () => {
    if (props.data.currentPage < totalPages.value) {
        emit("menu-click", props.data.currentPage + 1);
    }
};
</script>

<template>
    <!-- Text for desktop devices -->
    <div class="hidden sm:block sm:w-full text-left">
        <p class="text-xs text-gray-500 leading-5">
            <span class="font-bold">{{ totalPages }}</span> page<span v-if="totalPages>1">s</span> of results
        </p>
    </div>
    <nav
        v-if="data.total > data.perPage"
        role="navigation"
        aria-label="Pagination Navigation"
        class="flex items-center text-xs justify-center bg-white rounded-md shadow-sm mr-2"
    >
        <!-- Previous Button -->
        <button
            @click="prevClick"
            :disabled="data.currentPage === 1"
            class="disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray-200 relative inline-flex 
            items-center px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-300 border border-gray-300 shadow-sm rounded-l-md"
        >
            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                <path
                    fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                />
            </svg>
        </button>

        <!-- Page Numbers -->
        <template v-for="(page, index) in displayedPages" :key="index">
            <button
                v-if="page !== '...'"
                @click="goToPage(page)"
                class="px-3 py-2 border text-gray-600 hover:bg-gray-300 transition"
                :class="{ 'bg-primary-tethys text-white font-bold': page === data.currentPage }"
            >
                {{ page }}
            </button>
            <span v-else class="px-3 py-2 border border-gray-100 font-bold text-gray-300">...</span>
        </template>

        <!-- Next Button -->
        <button
            @click="nextClick"
            :disabled="data.currentPage === totalPages"
            class="disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray-200 relative inline-flex 
            items-center px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-300 border border-gray-300 shadow-sm rounded-r-md"
        >
            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                />
            </svg>
        </button>
    </nav>
</template>
