import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card panel-default" }
const _hoisted_2 = { class: "panel-heading" }
const _hoisted_3 = { class: "panel-title titlecase filterViewModelName" }
const _hoisted_4 = { class: "panel-body" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "card-footer"
}
const _hoisted_7 = { class: "card-footer-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.categoryAlias), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("ul", {
        class: _normalizeClass(["filter-items list-unstyled", { limited: _ctx.facetItems.length > 1 && _ctx.collapsed }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facetItems, (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: "list-group-item titlecase"
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(item.active ? 'disabled' : ''),
              onClick: _withModifiers(($event: any) => (_ctx.activateItem(item)), ["prevent"])
            }, _toDisplayString(_ctx.itemAlias(item.val)) + " (" + _toDisplayString(item.count) + ") ", 11, _hoisted_5)
          ]))
        }), 128))
      ], 2)
    ]),
    (_ctx.facetItems.length > 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, [
            (_ctx.collapsed)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle()))
                }, [
                  _createTextVNode(_toDisplayString(_ctx.uncollapseLabelText) + "  ", 1),
                  _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fa fa-angle-down" }, null, -1))
                ]))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggle()))
                }, [
                  _createTextVNode(_toDisplayString(_ctx.uncollapseLabelText) + "  ", 1),
                  _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fa fa-angle-up" }, null, -1))
                ]))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}