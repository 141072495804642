<template>
    <div id="page_style" class="flex flex-col items-center w-full h-full bg-transparent overflow-hidden" autocomplete="off">
        <!-- Search input section -->
        <div class="search-banner w-full bg-cover bg-no-repeat py-24 min-h-[400px] bg-[position:50%_25%]">
            <vs-input v-bind:propDisplay="searchTerm" v-bind:placeholder="'Enter your search term...'" @search-change="onSearch"></vs-input>
        </div>

        <!-- Results area on top of the list of publications for non-mobile devices -->
        <div class="hidden sm:flex w-full">
            <!-- (LEFT) sidebar section. Empty, just to keep symmetry -->
            <div class="md:w-4/12 lg:w-3/12"></div>

            <!-- (RIGHT) Section for Count of results  -->
            <div class="bg-[#eff3f7] h-12 rounded-md md:w-8/12 lg:w-9/12 mt-3 ml-5 mr-4 pl-3 flex items-center justify-between">
                <!-- Display message if a search term exists -->
                <!-- <div v-if="hasSearchTerm()" class="p-1 mb-0 text-sm bg-white rounded-lg" role="alert"> -->
                <div v-if="hasSearchTerm()" class="px-2 py-1 text-xs bg-white rounded-md" role="alert">
                    <span class="font-medium">Your search term </span> 
                    <span class="font-semibold">{{ "'" + stringSearchTerm + "'" }}</span> 
                    yielded <strong>{{ results.length > 0 ? numFound : 0 }}</strong> results:
                </div>
                <!-- Pagination before search results -->
                <PaginationTop class="pr-2 ml-auto" v-bind:data="pagination" @menu-click="onMenuClick"></PaginationTop>
            </div>
        </div>

        <!-- MOBILE DEVICES ONLY: Active filters and Results area on top of the filters menu ==================================================  -->
        <div class="sm:hidden w-full">

            <!-- MOBILE DEVICES ONLY: Active filters -->
            <div v-if="activeFilterCategories && Object.keys(activeFilterCategories).length > 0" class="mx-6 my-4">
                <span v-for="(values, key, index) in activeFilterCategories" v-bind:key="index" class="active-filter-items">
                    <!-- Active filter categories -->
                    <active-facet-category
                        v-bind:filterItems="values"
                        v-bind:categoryName="key"
                        @clear-facet-category="onClearFacetCategory"
                        class="text-xs"
                    ></active-facet-category>
                </span>
            </div>

            <!-- MOBILE DEVICES ONLY: Section for Count of results -->
            <div class="bg-[#eff3f7] rounded-md mx-5 p-2 my-4 flex items-center justify-between">
                <!-- Display message if a search term exists -->
                <!-- <div v-if="hasSearchTerm()" class="p-1 mb-0 text-sm bg-white rounded-lg" role="alert"> -->
                <div v-if="true" class="px-2 py-1 text-xs bg-white rounded-md" role="alert">
                    <span v-if="hasSearchTerm()" class="font-semibold">{{ "'" + stringSearchTerm + "' " }}</span> 
                    <span v-if="hasSearchTerm()" class="">offers </span> 
                    <strong>{{ results.length > 0 ? numFound : 0 }}</strong> results
                </div>

                <!-- Pagination before search results -->
                <!-- <PaginationTop class="pr-2 ml-auto" v-bind:data="pagination" @menu-click="onMenuClick"></PaginationTop> -->

                <!-- <a v-if="hasSearchTerm()" -->
                <a
                    class="inline-block px-2 py-1 ml-2 mr-1 border border-gray-300 shadow-sm hover:bg-gray-300 transition-colors text-xs font-normal text-primary-tethys rounded cursor-pointer"
                    :title="$t('go_to_results')"
                >
                    <i class="fas fa-arrow-down" @click="scrollToResults()"></i>
                </a>

            </div>

        </div>

        <!-- Area with the list of facets (LEFT) and list of publications (RIGHT) -->
        <div class="flex flex-col md:flex-row w-full">
            
            <!-- LEFT: Sidebar with facets (will appear on TOP on MOBILE DEVICES) -->
            <!-- <div class="w-full md:w-4/12 2xl:w-3/12 text-sm p-4"> -->
            <div class="w-full md:w-4/12 lg:w-3/12 text-sm px-5 pt-5">
                <div id="externals">
                    <div v-for="(facetItems, key, index) in facets" v-bind:key="index" class="mb-0">
                        <facet-category v-bind:facetItems="facetItems" v-bind:filterName="key" @filter="onFilter"></facet-category>
                    </div>
                </div>
            </div>

            <!-- RIGHT: Main results section with pagination and active filters -->
            <!-- <div class="flex flex-col w-full md:w-8/12 2xl:w-9/12 p-4"> -->
            <div class="flex flex-col md:w-8/12 lg:w-9/12 text-sm p-4">
                
                <!-- Active filters for Non-mobile devices -->
                <div v-if="activeFilterCategories && Object.keys(activeFilterCategories).length > 0" class="hidden sm:block mb-4">
                    <span v-for="(values, key, index) in activeFilterCategories" v-bind:key="index" class="active-filter-items">
                        <!-- Active filter categories -->
                        <active-facet-category
                            v-bind:filterItems="values"
                            v-bind:categoryName="key"
                            @clear-facet-category="onClearFacetCategory"
                        ></active-facet-category>
                    </span>
                </div>

                <div class="results">
                    
                    <!-- Results area on top of the list of publications for MOBILE devices -->
                    <div class="sm:hidden flex w-full">
                        <!-- Section for Count of results -->
                        <div class="bg-[#eff3f7] h-12 rounded-md my-3 mx-2 flex w-full items-center justify-between">
                            <!-- Display message if a search term exists -->
                            <!-- <div v-if="hasSearchTerm()" class="p-1 mb-0 text-sm bg-white rounded-lg" role="alert"> -->
                            <!-- <div v-if="true" class="px-2 py-1 text-xs bg-white rounded-md" role="alert">
                                <span class="font-medium">Your search term </span> 
                                <span class="font-semibold">{{ "'" + stringSearchTerm + "'" }}</span> 
                                yielded <strong>{{ results.length > 0 ? numFound : 0 }}</strong> results:
                            </div> -->
                            <!-- Pagination before search results -->
                            <a
                                class="inline-block px-2 py-1 ml-2 mr-1 border border-gray-300 shadow-sm hover:bg-gray-300 transition-colors text-xs font-normal text-primary-tethys rounded cursor-pointer"
                                :title="$t('go_to_search_bar')"
                            >
                                <i class="fas fa-arrow-up" @click="scrollToTop()"></i>
                            </a>
                            <PaginationTop class="pr-2 ml-auto" v-bind:data="pagination" @menu-click="onMenuClick"></PaginationTop>
                        </div>
                    </div>



                    <!-- Results section -->
                    <vs-result v-bind:datasets="results"></vs-result>


                    <!-- Pagination after search results -->
                    <!-- <PaginationTop class="mt-5" v-bind:data="pagination" @menu-click="onMenuClick"></PaginationComponent> -->
                </div>
            </div>
        </div>

        <!-- Pages-found area below the list of publications for non-mobile devices -->
        <div class="hidden sm:flex w-full">
            <!-- LEFT sidebar section. Empty, just to keep symmetry -->
            <div class="md:w-4/12 lg:w-3/12"></div>

            <!-- Section for pagination element (RIGHT) -->
            <div class="bg-[#eff3f7] h-12 rounded-md md:w-8/12 lg:w-9/12 mt-3 ml-5 mr-4 pl-3 flex items-center justify-end">
                <!-- Display message if a search term exists -->
                <!-- <div v-if="hasSearchTerm()" class="px-2 py-1 text-xs bg-white rounded-md" role="alert">
                    <span class="font-medium">Your search term </span> 
                    <span class="font-semibold">{{ "'" + stringSearchTerm + "'" }}</span> 
                    yielded <strong>{{ results.length > 0 ? numFound : 0 }}</strong> results:
                </div> -->
                <!-- Pagination List after search results -->
                <PaginationBase v-bind:data="pagination" @menu-click="onMenuClick"></PaginationBase>
            </div>
        </div>

        <!-- Partner logos section -->
        <div class="w-full mx-auto mt-5 px-4">
            <div class="flex flex-wrap justify-between items-center">
                <div class="flex justify-center w-full md:w-1/3 p-4">
                    <a target="_blank" href="https://www.re3data.org/repository/r3d100013400">
                        <img src="@/assets/site/img/re3-data-logo-mono.jpg" alt="re3 data logo" class="w-full max-w-[200px]" />
                    </a>
                </div>
                <div class="flex justify-center w-full md:w-1/3 p-4">
                    <a target="_blank" href="http://www.geosphere.at/">
                        <img src="@/assets/site/img/geosphere-austria-logo.jpg" alt="logo geosphere austria" class="w-full max-w-[400px]" />
                    </a>
                </div>
                <div class="flex justify-center w-full md:w-1/3 p-4">
                    <a target="_blank" href="https://www.base-search.net/Search/Results?q=coll:fttethysrdr&refid=dctablede">
                        <img src="@/assets/site/img/base_logo.png" alt="logo base" class="w-full max-w-[250px]" />
                    </a>
                </div>
            </div>
        </div>


    </div>
</template>

<script lang="ts">
import PaginationBase from "@/components/PaginationBase.vue";
import SearchViewComponent from "./search-view-component";
export default SearchViewComponent;
// Import Tailwind CSS
import "@/index.css";
</script>

<style scoped>
.search-banner {
    background-image: url('/src/assets/site/img/main-search-banner.jpg');
}


/* Additional Tailwind adjustments */
/* .bg-blue-custom { background-color: #06bcef; }
.text-mouse-grey { color: #6c6e6b; }
.card { 
    background-color: white; 
    border: 1px solid rgba(0, 0, 0, 0.125); 
    border-radius: 0.25rem; 
} */

/* Custom responsive styles for banner */
/* @media (max-width: 576px) {
    .banner {
        align-items: flex-start;
        height: 400px;
    }
} */
</style>
