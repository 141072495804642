import { Dataset } from "@/models/dataset";
import { Component, Vue, Prop } from "vue-facing-decorator";

import ResultsMinimap from "@/components/results-minimap/results-minimap.vue";


@Component({
    name: "VsResult",
    components: {
        ResultsMinimap
    },
})
export default class VsResult extends Vue {
    public openAccessLicences: Array<string> = ["CC-BY-4.0", "CC-BY-SA-4.0"];

    @Prop()
    private datasets!: Array<Dataset>;

    public get results(): Array<Dataset> {
        return this.datasets;
    }

    /* Provides the bounds of the publication for the Leaflet minimap */
    public getBounds(document: Dataset): L.LatLngBoundsLiteral | string {
        // if (this.coverage != undefined) { 
            return [
                [Number(document.bbox_ymin), Number(document.bbox_xmin)], // Southwest corner
                [Number(document.bbox_ymax), Number(document.bbox_xmax)]  // Northeast corner
            ];
        // } else {
        //     return ""; 
        // }
    }

    public simplifyAuthor(author:string): string {
        
        if (author.endsWith(" ")) { 
            return author.substring(0, author.indexOf(","));
        } else {
            let firstNameInitial:string = author.charAt(author.indexOf(",") + 2);
            return author.substring(0, author.indexOf(",") + 2) + firstNameInitial;
        }
    }

    public getDomainWithoutSubdomain(): string {
        const urlParts = new URL(window.location.href).hostname.split(".");

        return urlParts
            .slice(0)
            .slice(-(urlParts.length === 4 ? 3 : 2))
            .join(".");
    }

    // private convert(unixtimestamp: number): string { // SOLR
    private convert(unixtimestamp: string, locale: string): string { // OpenSearch
        // Unixtimestamp
        // var unixtimestamp = document.getElementById('timestamp').value;
        // Months array
        let months_arr = [];
        if (locale == "DE") {
            months_arr = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
        } else {
            months_arr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        }
        // Convert timestamp to milliseconds
        // const date = new Date(unixtimestamp * 1000); // SOLR
        const date = new Date(Number(unixtimestamp) * 1000); // OpenSearch
        // Year
        const year = date.getFullYear();
        // Month
        const month = months_arr[date.getMonth()];
        // Day
        const day = date.getDate();
        // Display date time in MM-dd-yyyy h:m:s format
        const convdataTime = month + " " + day + ", " + year;
        // document.getElementById('datetime').innerHTML = convdataTime;
        return convdataTime;
    }

}
