import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from './assets/images/TETHYS-Logo.svg'
import _imports_1 from './assets/images/cts-logo.png'
import _imports_2 from '@/assets/site/img/geosphere-austria-logo-negativ.png'


const _hoisted_1 = {
  class: "navbar navbar-light border-bottom",
  role: "navigation",
  "aria-label": "main navigation"
}
const _hoisted_2 = { class: "navbar-brand" }
const _hoisted_3 = {
  class: "navbar-start",
  style: {"flex-grow":"1","justify-content":"center"}
}
const _hoisted_4 = { class: "navbar-item" }
const _hoisted_5 = { class: "navbar-item" }
const _hoisted_6 = { class: "navbar-item" }
const _hoisted_7 = { class: "navbar-item" }
const _hoisted_8 = { class: "navbar-item" }
const _hoisted_9 = { class: "navbar-end" }
const _hoisted_10 = { class: "navbar-item" }
const _hoisted_11 = { class: "buttons" }
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "container-fluid" }
const _hoisted_14 = { class: "columns" }
const _hoisted_15 = { class: "column col-sm" }
const _hoisted_16 = {
  class: "card mx-auto",
  style: {"width":"18rem"}
}
const _hoisted_17 = { class: "card-body" }
const _hoisted_18 = { class: "list-group list-group-flush" }
const _hoisted_19 = { class: "list-group-item" }
const _hoisted_20 = { class: "column col-sm" }
const _hoisted_21 = {
  class: "card mx-auto",
  style: {"width":"18rem"}
}
const _hoisted_22 = { class: "card-body" }
const _hoisted_23 = { class: "list-group list-group-flush" }
const _hoisted_24 = { class: "list-group-item" }
const _hoisted_25 = { class: "list-group-item" }
const _hoisted_26 = { class: "list-group-item" }
const _hoisted_27 = { class: "list-group-item" }
const _hoisted_28 = { class: "container-fluid" }
const _hoisted_29 = { class: "copyright-notice" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("a", {
          class: "navbar-item",
          href: "/"
        }, [
          _createElementVNode("img", {
            src: _imports_0,
            class: "w-52 sm:w-60 sm:h-[86px]"
          }),
          _createTextVNode("    "),
          _createElementVNode("a", {
            href: "https://doi.org/10.34894/TKWVFL",
            target: "_blank"
          }, [
            _createElementVNode("img", {
              src: _imports_1,
              class: "w-12 h-12 sm:w-20 sm:h-20"
            })
          ])
        ], -1)),
        _createElementVNode("a", {
          id: "menu-icon",
          role: "button",
          class: _normalizeClass(["navbar-burger", _ctx.active ? 'is-active' : '']),
          "aria-label": "menu",
          "aria-expanded": "false",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showMobilemenu && _ctx.showMobilemenu(...args)))
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1)
        ]), 2)
      ]),
      _createElementVNode("div", {
        id: "navMenu",
        class: _normalizeClass(["navbar-menu", _ctx.active ? 'is-active' : ''])
      }, [
        _createElementVNode("ul", _hoisted_3, [
          _createElementVNode("li", _hoisted_4, [
            _createVNode(_component_router_link, {
              class: "navbar-link is-arrowless",
              to: "/"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("STARTSEITE")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_5, [
            _createVNode(_component_router_link, {
              class: "navbar-link is-arrowless",
              to: "/search"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("SEARCH")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_6, [
            _createVNode(_component_router_link, {
              class: "navbar-link is-arrowless",
              to: "/services"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("SERVICES")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_7, [
            _createVNode(_component_router_link, {
              class: "navbar-link is-arrowless",
              to: "/help"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("HELP")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_8, [
            _createVNode(_component_router_link, {
              target: "_blank",
              class: "navbar-link is-arrowless",
              to: "/oai"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("OAI")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("a", {
                href: _ctx.portal,
                target: "_blank",
                class: "button is-primary custom-button"
              }, _cache[8] || (_cache[8] = [
                _createElementVNode("i", { class: "fas fa-sign-in-alt" }, null, -1),
                _createElementVNode("strong", null, "Sign in", -1)
              ]), 8, _hoisted_12)
            ])
          ])
        ])
      ], 2)
    ]),
    _createVNode(_component_router_view),
    _createElementVNode("footer", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _cache[11] || (_cache[11] = _createElementVNode("h5", { class: "card-title" }, "About TETHYS", -1)),
              _createElementVNode("ul", _hoisted_18, [
                _createElementVNode("li", _hoisted_19, [
                  _createVNode(_component_router_link, { to: "/oai" }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("OAI")
                    ])),
                    _: 1
                  })
                ]),
                _cache[10] || (_cache[10] = _createStaticVNode("<li class=\"list-group-item\" data-v-01763968><a href=\"https://www.geosphere.at/\" target=\"_blank\" data-v-01763968>geosphere.at</a></li><li class=\"list-group-item\" data-v-01763968><a href=\"https://www.geologie.ac.at/news/news\" target=\"_blank\" data-v-01763968>News</a></li><li class=\"list-group-item\" data-v-01763968><a href=\"docs/PreservationPlanTethys.pdf\" target=\"_blank\" data-v-01763968>Preservation Plan</a></li><li class=\"list-group-item\" data-v-01763968><a href=\"docs/HandbuchTethys.pdf\" target=\"_blank\" data-v-01763968>Manual</a></li>", 4))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _cache[16] || (_cache[16] = _createElementVNode("h5", { class: "card-title" }, "Tools and Support", -1)),
              _createElementVNode("ul", _hoisted_23, [
                _createElementVNode("li", _hoisted_24, [
                  _createVNode(_component_router_link, { to: "/contact" }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createElementVNode("i", { class: "far fa-id-card" }, null, -1),
                      _createTextVNode(" Contact")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("li", _hoisted_25, [
                  _createVNode(_component_router_link, { to: "/imprint" }, {
                    default: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createElementVNode("i", { class: "fas fa-stamp" }, null, -1),
                      _createTextVNode(" Imprint")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("li", _hoisted_26, [
                  _createVNode(_component_router_link, { to: "/sitelinks" }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createElementVNode("i", { class: "fas fa-link" }, null, -1),
                      _createTextVNode(" Sitelinks")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("li", _hoisted_27, [
                  _createVNode(_component_router_link, { to: "/terms-and-conditions" }, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createElementVNode("i", { class: "far fa-file-alt" }, null, -1),
                      _createTextVNode(" Terms & Conditions")
                    ])),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]),
        _cache[17] || (_cache[17] = _createStaticVNode("<div class=\"column col-sm\" data-v-01763968><div class=\"card mx-auto\" style=\"width:18rem;\" data-v-01763968><div class=\"card-body\" data-v-01763968><h5 class=\"card-title\" data-v-01763968>Connect with us</h5><ul class=\"list-group list-group-flush\" data-v-01763968><li class=\"list-group-item\" data-v-01763968><a target=\"_blank\" href=\"https://www.geosphere.at/\" data-v-01763968><i class=\"fas fa-home pr-2\" data-v-01763968></i>geosphere.at</a></li><li class=\"list-group-item\" data-v-01763968><a href=\"#\" data-v-01763968><i class=\"fas fa-phone-alt pr-2\" data-v-01763968></i> +43-1-7125674</a></li><li class=\"list-group-item\" data-v-01763968><a href=\"mailto:repository@geologie.ac.at\" data-v-01763968><i class=\"fas fa-envelope pr-2\" data-v-01763968></i> repository(at)geosphere.at </a></li></ul><img class=\"img-fluid pt-4\" src=\"" + _imports_2 + "\" alt=\"GeoSphere Austria Logo\" data-v-01763968></div></div></div>", 1))
      ])
    ]),
    _createElementVNode("div", _hoisted_28, [
      _createElementVNode("div", _hoisted_29, "© " + _toDisplayString(_ctx.currentYear) + " TETHYS RDR", 1)
    ])
  ], 64))
}