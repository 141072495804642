<!-- Contains the template and references the TypeScript logic. -->
<template>
  <div id="map" style="height: 300px;"></div>
</template>

<script lang="ts">
import Minimap from './Minimap';
export default Minimap;
</script>

<style scoped>
/* #map {
  height: 50%;
  width: 100%;
} */

/* Target the leaflet control */

/* The order of the following is critical, otherwise the styles don't apply properly: */

:deep(.leaflet-control-layers) {
  @apply w-[34px] h-[34px]; 
}

:deep(.leaflet-control-layers-expanded) {
  @apply w-32 h-20 text-xs;
} 

:deep(.leaflet-control-layers-toggle) {
  /* @apply bg-no-repeat !bg-[length:45%] bg-[position:25%_25%] */
  @apply bg-no-repeat !bg-[length:20px_20px] !bg-[position:20%_20%]
}


:deep(.custom-attribution) {
  @apply absolute bottom-0.5 right-0 w-[18px] h-[18px] bg-primary-tethys border-2 border-white rounded-full flex items-center justify-center cursor-pointer font-bold text-xs text-white shadow-sm shadow-gray-300 z-[1000];
  /* @apply absolute bottom-0.5 right-0 w-5 h-5 bg-primary-tethys border-2 border-white rounded-full flex items-center justify-center cursor-pointer font-bold text-xs text-white shadow-sm shadow-gray-300 z-[1000]; */
}

:deep(.attribution-text) {
  @apply absolute pl-2 w-24 bottom-3.5 right-2.5 bg-white border-secondary-tethys rounded-full justify-start text-xs leading-tight z-[999] shadow-sm shadow-gray-300 origin-right scale-x-0 opacity-0 transition-transform duration-300;
}

:deep(.attribution-text:not(.hidden)) {
  @apply scale-x-100 opacity-100;
}

:deep(.custom-attribution:hover) {
  /* @apply shadow-md shadow-gray-600; */
  @apply bg-black;
}

.hidden {
  display: none;
}


:deep(.refresh-button) {
  @apply w-[34px] h-[34px] bg-white border-2 border-gray-300 rounded-md  flex items-center justify-center cursor-pointer text-gray-600 text-sm;
  /* @apply transition duration-200 ease-in-out; */
  /* 2px solid rgba(0,0,0,0.2); */
}

:deep(.refresh-button:hover) {
  @apply bg-gray-100 text-gray-800;
}

:deep(.refresh-button > *) {
  @apply m-auto;
}


</style>