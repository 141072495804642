import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "is-multiline" }
const _hoisted_2 = { class: "column is-two-thirds-tablet is-half-desktop is-one-third-widescreen mx-auto" }
const _hoisted_3 = { class: "search-box mx-auto" }
const _hoisted_4 = { class: "field has-addons main-search-from-bg" }
const _hoisted_5 = { class: "control is-expanded" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "control" }
const _hoisted_10 = { class: "column is-two-thirds-tablet is-half-desktop is-one-third-widescreen mx-auto" }
const _hoisted_11 = { class: "autocomplete-results pure-u-23-24" }
const _hoisted_12 = {
  key: 0,
  class: "loading"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "small-label" }
const _hoisted_15 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              id: "search_query",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.display) = $event)),
              class: "input is-medium",
              type: "text",
              name: "q",
              autocomplete: "off",
              placeholder: _ctx.placeholder,
              onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.searchChanged && _ctx.searchChanged(...args))),
              onKeydown: [
                _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onArrowDown && _ctx.onArrowDown(...args)), ["down"])),
                _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onArrowUp && _ctx.onArrowUp(...args)), ["up"])),
                _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"])),
                _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["tab"]))
              ],
              onFocus: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args)))
            }, null, 40, _hoisted_6), [
              [_vModelText, _ctx.display]
            ])
          ]),
          (_ctx.display)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("button", {
                  class: "px-2.5 py-1 ml-1 mr-0.5 text-gray-400 border border-gray-100",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.clearSearch())),
                  title: _ctx.$t('clean_search_bar')
                }, _cache[9] || (_cache[9] = [
                  _createElementVNode("i", { class: "fas fa-times" }, null, -1)
                ]), 8, _hoisted_8)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("button", {
              class: "button input is-medium search-button-icon",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.search()))
            }, _cache[10] || (_cache[10] = [
              _createElementVNode("i", { class: "fas fa-search text-white" }, null, -1)
            ]))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _withDirectives(_createElementVNode("ul", _hoisted_11, [
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("li", _hoisted_12, "Loading results..."))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.suggestions, (result, i) => {
              return (_openBlock(), _createElementBlock("li", {
                key: i,
                ref_for: true,
                ref: _ctx.setItemRef,
                class: _normalizeClass(["autocomplete-result-item", { 'is-active': _ctx.isSelected(i) }]),
                onClick: _withModifiers(($event: any) => (_ctx.select(result)), ["prevent"])
              }, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("label", {
                    innerHTML: _ctx.formatSuggestion(result)
                  }, null, 8, _hoisted_15)
                ])
              ], 10, _hoisted_13))
            }), 128))
      ], 512), [
        [_vShow, _ctx.showResults]
      ])
    ])
  ]))
}