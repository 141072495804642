import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id", "name"]
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "gsaterm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      id: _ctx.categoryAlias,
      name: _ctx.categoryAlias,
      type: "checkbox",
      checked: "checked",
      class: "css-checkbox",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.deactivateFacetCategory()), ["prevent"]))
    }, null, 8, _hoisted_1),
    _createElementVNode("label", {
      for: _ctx.categoryAlias,
      class: "css-label"
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.categoryAlias + ": "), 1),
      (_ctx.filterItems && _ctx.filterItems.length > 0)
        ? (_openBlock(), _createElementBlock("a", _hoisted_3, _toDisplayString(_ctx.getFilterItemsAlias(_ctx.categoryAlias)), 1))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2)
  ]))
}