import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "section" }
const _hoisted_3 = { class: "columns is-centered" }
const _hoisted_4 = { class: "column is-6-desktop" }
const _hoisted_5 = {
  key: 0,
  class: "list"
}
const _hoisted_6 = { class: "block-list has-radius is-primary" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "columns is-centered" }
const _hoisted_9 = { class: "column is-6-desktop" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "post" }
const _hoisted_12 = { class: "post-header" }
const _hoisted_13 = { class: "post-title" }
const _hoisted_14 = { class: "blog-meta" }
const _hoisted_15 = { class: "post-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "title has-text-centered" }, [
        _createElementVNode("h1", { class: "title" }, "Sitelinks for Web Crawlers"),
        _createElementVNode("hr", { class: "center-line" })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.years.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("ul", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.years, (year, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      class: _normalizeClass({ highlight: year == _ctx.selected }),
                      onClick: ($event: any) => (_ctx.select(year))
                    }, _toDisplayString(year), 11, _hoisted_7))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          (_ctx.datasets.length > 0)
            ? (_openBlock(), _createElementBlock("ol", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.datasets, (dataset, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("header", _hoisted_12, [
                        _createElementVNode("h2", _hoisted_13, [
                          _createElementVNode("a", null, _toDisplayString(dataset.type) + "; " + _toDisplayString(dataset.publish_id), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_14, _toDisplayString(dataset.server_date_published), 1),
                      _createElementVNode("div", _hoisted_15, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataset.authors, (author, index_a) => {
                          return (_openBlock(), _createElementBlock("div", { key: index_a }, [
                            _createElementVNode("em", null, "Author: " + _toDisplayString(author.full_name), 1),
                            _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1))
                          ]))
                        }), 128)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataset.titles, (title, index_t) => {
                          return (_openBlock(), _createElementBlock("div", { key: index_t }, [
                            _createElementVNode("em", null, _toDisplayString(title.type) + ": " + _toDisplayString(title.value), 1),
                            _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
                          ]))
                        }), 128))
                      ])
                    ])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}