import { Component, Vue, Prop } from "vue-facing-decorator";

@Component({
  name: "Accordion",
})
export default class Accordion extends Vue {
  @Prop({ type: Number, required: true }) itemCount!: number; // Number of accordion items
  @Prop({ type: String, default: '' }) customClass!: string;

  // @Prop({ type: Number, default: 0 }) defaultActiveIndex!: number;

  // Update `activeIndex` to use the default value on initialization.
  // private activeIndex: number | null = this.defaultActiveIndex;
  private activeIndex: number | null = null;

  // Update the `toggleAccordion` method to respect external changes if needed.
  toggleAccordion(index: number): void {
    this.activeIndex = this.activeIndex === index ? null : index;
  }

  // Update the `isActive` method to reflect changes dynamically.
  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  openAccordion(index: number): void {
    this.activeIndex = index;
  }
  
}