import { Component, Prop, Vue } from 'vue-facing-decorator';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';

@Component({
  name: 'Minimap',
})
export default class Minimap extends Vue {
  @Prop({ type: Array, required: true }) bounds!: L.LatLngBoundsLiteral;

  // private originalCenter: L.LatLngExpression = [47.71, 13.55]; // Original center
  // private originalZoom: number = 6; // Original zoom level

  // Bounding box around a circular marker's center to fit map bounds
  private cicleMarkerBounds: any = "";

  /**
   * Lifecycle hook called when the component is mounted.
   * Initializes the Leaflet map, sets up base layers, and adds either a circle or rectangle
   * based on the `bounds` prop passed to the component.
   */
  mounted() {
    // Initialize the map with specific center and zoom
    const map = L.map('map', {
      center: [47.71, 13.55], // Initial center coordinates
      zoomControl: false,      // Enable zoom controls
      zoom: 6,                // Initial zoom level
      minZoom: 5,             // Minimum zoom level allowed
      maxBounds: [
        [44.0, 9.0],          // Southwest corner of the bounding box
        [51.0, 18.0]          // Northeast corner of the bounding box
      ],
      maxBoundsViscosity: 1.0 // Prevent map from being dragged outside the defined bounds
    });
  
    // Remove Leaflet logo and text
    // map.attributionControl.setPrefix(false);

    // Hide the default Leaflet attribution control
    map.attributionControl.remove();

    // Add the zoom control to the top right
    L.control.zoom({
      position: 'topright'
    }).addTo(map);


    // Add basemap.at tile layer to the map
    const basemapAtLayer = L.tileLayer('https://mapsneu.wien.gv.at/basemap/geolandbasemap/normal/google3857/{z}/{y}/{x}.png', {
      attribution: '<a href="https://www.basemap.at">basemap.at</a>',
      noWrap: true,
      subdomains: ['', '1', '2', '3', '4']
    }).addTo(map);
  
    // Add Esri imagery tile layer
    const esriImageryLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; <a href="https://www.esri.com/" target="_blank">Esri</a>'
    });
  
    // Add Esri topo map tile layer
    const esriTopoLayer = L.tileLayer('https://server.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; <a href="https://www.esri.com/" target="_blank">Esri</a>'
    });
  
    // Define available base maps for the user to toggle between
    const baseMaps = {
      //   "OpenStreetMap": openStreetMapLayer,
      "basemap.at": basemapAtLayer,
      "ESRI Imagery": esriImageryLayer,
      "ESRI Topo": esriTopoLayer
    };
  
    // Add the layers control to the bottom left
    L.control.layers(baseMaps, {}, { position: 'bottomleft' }).addTo(map);
  

    // Add the custom attribution control with the "i" icon
    const AttributionControl = L.Control.extend({
      options: {
        position: 'bottomright', // Place the control at the bottom-right corner
      },
      onAdd: function () {
        // Create the container for the "i" icon
        const container = L.DomUtil.create('div', 'custom-attribution');
        container.innerHTML = `<div class="info-icon">i</div>`;

        // Set a title attribute for the tooltip (built-in Leaflet feature)
        container.title = 'Basemap source'; // Tooltip text

        // Add a click event to toggle visibility of the attribution text
        L.DomEvent.on(container, 'click', () => {
          const attributionText = document.querySelector('.attribution-text');
          if (attributionText) {
            attributionText.classList.toggle('hidden'); // Toggle visibility
          }
        });

        return container;
      },
    });

    // Add the custom control to the map
    const attributionControl = new AttributionControl();
    map.addControl(attributionControl);

    // Add the hidden attribution text element
    const attributionText = L.DomUtil.create(
      'div',
      'attribution-text hidden', // Initially hidden
      map.getContainer() // Attach it to the map container
    );

    // Set the attribution text
    attributionText.innerHTML = basemapAtLayer.getAttribution
      ? basemapAtLayer.getAttribution() || ''
      : '';

    // Dynamically update attribution on basemap change
    map.on('baselayerchange', (event) => {
      const newAttribution = event.layer.getAttribution
        ? event.layer.getAttribution()
        : '';
      attributionText.innerHTML = newAttribution || '';
    });


    const [southWest, northEast] = this.bounds;
  
    if (!(southWest[0] === northEast[0] || southWest[1] === northEast[1])) {
      // If bounds are not equal, draw a rectangle
      const rectangle = L.rectangle(this.bounds, { 
        color: '#30D5C8', // Rectangle outline color //Alternative color: 336699
        weight: 2,        // Outline thickness
        opacity: 1        // Opacity of the rectangle outline
      }).addTo(map); 

      // Add a click event handler to the Rectangle
      rectangle.on('click', () => {
        map.fitBounds(this.bounds, { padding: [18, 18] }); // Adjust map to fit within rectangle bounds
      });
  
      // Automatically adjust the map's view to fit the rectangle's bounds with padding
      map.fitBounds(this.bounds, { padding: [18, 18] });

    } else {
      // If y_min and y_max (OR x_min and x_max) are equal, generate a circle
      const center = [southWest[0], southWest[1]] as [number, number];
      // Add a CircleMarker to the map at the center of the bounds. This kind of marker is used to maintain constant size regardless of zoom level
      const circleMarker = L.circleMarker(center, {
        color: '#30D5C8',       // Outline color
        fillColor: '#336699',   // Fill color
        fillOpacity: 1,         // Opacity of the fill
        opacity: 0.5,           // Outline opacity
        weight: 10,             // Outline weight (thickness)
        radius: 10              // Radius in pixels
      }).addTo(map);
  
      // Manually create a small bounding box around the marker's center to fit bounds
      const buffer = 0.01; // Buffer size around the marker. Adjust this value to control the area around the marker
      this.cicleMarkerBounds = L.latLngBounds(
        [center[0] - buffer, center[1] - buffer], // Southwest corner of the bounding box
        [center[0] + buffer, center[1] + buffer]  // Northeast corner of the bounding box
      );
  
      // Add a click event handler to the CircleMarker
      circleMarker.on('click', () => {
        map.fitBounds(this.cicleMarkerBounds, { padding: [10, 10] }); // Adjust map to fit within marker bounds
        console.log("circleMarkerBounds: ", this.cicleMarkerBounds);
      });
  
      // Automatically adjust the map's view to fit the marker's bounds
      map.fitBounds(this.cicleMarkerBounds, { padding: [10, 10] });
    }

    // Add a custom refresh control
    const RefreshControl = L.Control.extend({
      options: {
        position: 'topleft', // Place the button in the top-left corner
      },
      onAdd: () => {
        // Create the container for the button
        const container = L.DomUtil.create('div', 'refresh-button');
        container.innerHTML = '<i class="fa-solid fa-arrow-rotate-right"></i>'; // FontAwesome icon for the button
        container.title = 'Reset to original view'; // Tooltip for the button
    
        // Add a click event to reset the map's view
        L.DomEvent.on(container, 'click', () => {
          if (this.cicleMarkerBounds && this.cicleMarkerBounds.isValid()) {
            // If it's a circleMarker, fit to the small bounds
            map.fitBounds(this.cicleMarkerBounds, { padding: [10, 10] });
            // console.log('Resetting view to circleMarkerBounds:', this.cicleMarkerBounds);
          } else if (this.bounds) {
            // If it's a rectangle, fit to the rectangle bounds
            map.fitBounds(this.bounds, { padding: [18, 18] });
            // console.log('Resetting view to rectangle bounds:', this.bounds);
          } else {
            console.warn('No valid bounds to reset to.');
          }
        });
    
        return container;
      },
    });
    

    // Add the control to the map
    const refreshControl = new RefreshControl();
    map.addControl(refreshControl);
  }
  
}