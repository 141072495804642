import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "hidden sm:block sm:w-full text-left" }
const _hoisted_2 = { class: "text-xs text-gray-500 leading-5" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  role: "navigation",
  "aria-label": "Pagination Navigation",
  class: "flex items-center text-xs justify-center bg-white rounded-md shadow-sm mr-2"
}
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 1,
  class: "px-3 py-2 border border-gray-100 font-bold text-gray-300"
}
const _hoisted_9 = ["disabled"]

import { computed } from "vue";

const maxButtons = 7; // Adjusted to 7 for our case


export default /*@__PURE__*/_defineComponent({
  __name: 'PaginationBase',
  props: {
    data: {
        type: Object,
        default: () => ({}),
    },
},
  emits: ["menu-click"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const totalPages = computed(() => Math.ceil(props.data.total / props.data.perPage));
const displayedPages = computed(() => {
    const currentPage = props.data.currentPage;
    const pages: Array<number | string> = [];

    if (totalPages.value <= maxButtons) {
        // Show all pages if they fit within maxButtons
        for (let i = 1; i <= totalPages.value; i++) {
            pages.push(i);
        }
    } else {
        pages.push(1); // Always show the first page

        const middleRange = maxButtons - 2; // Remaining slots after first/last pages

        if (currentPage > 3) {
            pages.push("..."); // Add left ellipsis
        }

        // Define middle range: Center around current page, keeping within limits
        let startPage = Math.max(2, currentPage - 1);
        let endPage = Math.min(totalPages.value - 1, currentPage + 1);

        if (currentPage <= 3) {
            endPage = middleRange; // Ensure first few pages get shown
        }
        if (currentPage >= totalPages.value - 2) {
            startPage = totalPages.value - (middleRange - 1); // Ensure last few pages show correctly
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        if (currentPage < totalPages.value - 2) {
            pages.push("..."); // Add right ellipsis
        }

        pages.push(totalPages.value); // Always show last page
    }

    return pages;
});

const goToPage = (page: number | string) => {
    if (typeof page === "number") {
        emit("menu-click", page);
    }
};

const prevClick = () => {
    if (props.data.currentPage > 1) {
        emit("menu-click", props.data.currentPage - 1);
    }
};

const nextClick = () => {
    if (props.data.currentPage < totalPages.value) {
        emit("menu-click", props.data.currentPage + 1);
    }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(totalPages.value), 1),
        _cache[0] || (_cache[0] = _createTextVNode(" page")),
        (totalPages.value>1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "s"))
          : _createCommentVNode("", true),
        _cache[1] || (_cache[1] = _createTextVNode(" of results "))
      ])
    ]),
    (__props.data.total > __props.data.perPage)
      ? (_openBlock(), _createElementBlock("nav", _hoisted_5, [
          _createElementVNode("button", {
            onClick: prevClick,
            disabled: __props.data.currentPage === 1,
            class: "disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray-200 relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-300 border border-gray-300 shadow-sm rounded-l-md"
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("svg", {
              class: "w-4 h-4",
              fill: "currentColor",
              viewBox: "0 0 20 20"
            }, [
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                d: "M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z",
                "clip-rule": "evenodd"
              })
            ], -1)
          ]), 8, _hoisted_6),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(displayedPages.value, (page, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (page !== '...')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: ($event: any) => (goToPage(page)),
                    class: _normalizeClass(["px-3 py-2 border text-gray-600 hover:bg-gray-300 transition", { 'bg-primary-tethys text-white font-bold': page === __props.data.currentPage }])
                  }, _toDisplayString(page), 11, _hoisted_7))
                : (_openBlock(), _createElementBlock("span", _hoisted_8, "..."))
            ], 64))
          }), 128)),
          _createElementVNode("button", {
            onClick: nextClick,
            disabled: __props.data.currentPage === totalPages.value,
            class: "disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray-200 relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-600 hover:bg-gray-300 border border-gray-300 shadow-sm rounded-r-md"
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("svg", {
              class: "w-4 h-4",
              fill: "currentColor",
              viewBox: "0 0 20 20"
            }, [
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                d: "M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z",
                "clip-rule": "evenodd"
              })
            ], -1)
          ]), 8, _hoisted_9)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})